document.addEventListener('DOMContentLoaded', function() {
    const accordions = document.querySelectorAll('[data-item="accordion"]')

    function resetAccordion() {
        accordions.forEach((item) => {
            const itemHeader = item.querySelector('.main-accordion__header')
            const itemContent = item.querySelector('.main-accordion__content')
            const itemBtn = item.querySelector('[data-item="accordion-control"]')

            itemHeader.classList.remove('main-accordion__header--opened')
            itemContent.classList.remove('main-accordion__content--opened')
            itemBtn.classList.remove('main-accordion__btn--active')
        })
    }

    function toggleAccordion(accordion) {
        const header = accordion.querySelector('.main-accordion__header')
        const content = accordion.querySelector('.main-accordion__content')
        const btn = accordion.querySelector('[data-item="accordion-control"]')
        const isOpened = header.classList.contains('main-accordion__header--opened')

        if (isOpened) {
            header.classList.remove('main-accordion__header--opened')
            content.classList.remove('main-accordion__content--opened')
            btn.classList.remove('main-accordion__btn--active')
        } else {
            resetAccordion()
            header.classList.add('main-accordion__header--opened')
            content.classList.add('main-accordion__content--opened')
            btn.classList.add('main-accordion__btn--active')
        }
    }

    accordions.forEach((accordion) => {
        const header = accordion.querySelector('.main-accordion__header')

        header.addEventListener('click', function() {
            toggleAccordion(accordion)
        })
    })
})
