document.addEventListener('DOMContentLoaded', () => {
    const triggers = document.querySelectorAll('.menu-footer-accordion > ul > li > a')

    triggers.forEach(function(anchor) {
        anchor.addEventListener('click', function(event) {
            event.preventDefault()

            anchor.classList.toggle('is-active')

            const subMenu = anchor.nextElementSibling
            const className = 'sub-menu--opened'

            if (subMenu) {
                subMenu.classList.toggle(className)
            }
        })
    })

})
