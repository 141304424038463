document.addEventListener('DOMContentLoaded', () => {
    const menu = document.querySelector('#menu-aside')
    const btn = document.querySelector('#hamburger')

    if (!btn || !menu) {
        return
    }

    btn.addEventListener('click', () => {
        btn.classList.toggle('is-active')
        menu.classList.toggle('is-active')
    })

    const menuItems = document.querySelectorAll('#menu-aside > .menu-item > a')

    menuItems.forEach((item) => {
        item.addEventListener('click', function(event) {
            const subMenu = this.nextElementSibling
            if (!subMenu) {
                return null
            }

            event.preventDefault()
            const subMenuClass = 'sub-menu--opened'

            subMenu.classList.toggle(subMenuClass)
        })
    })
})
