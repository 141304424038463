import './../../scss/main.scss'
import '../layout/index'
import '../components/index'
import './mainFormBlock'
import MicroModal from 'micromodal'

// eslint-disable-next-line no-undef
if (BS_ON === 'true' && NODE_ENV === 'development') {
    const script = document.createElement('script')
    // eslint-disable-next-line no-undef
    script.src = `http://localhost:${BS_PORT}/browser-sync/browser-sync-client.js`
    document.head.appendChild(script)
}

document.addEventListener('DOMContentLoaded', function() {
    MicroModal.init()

    document.addEventListener('click', function(event) {
        const target = event.target

        if (target.tagName === 'A') {
            const href = target.getAttribute('href')

            if (href === '#') {
                event.preventDefault()
            } else if (href === '#modal') {
                event.preventDefault()
                MicroModal.show('modal')
            }
        }
    })
})

document.addEventListener('DOMContentLoaded', function() {
    const header = document.getElementById('masthead')

    function getHeaderHeight() {
        return header ? header.offsetHeight : 0
    }

    function handleAnchorClick(e) {
        if (
            e.target.tagName === 'A' &&
            e.target.getAttribute('href').startsWith('#')
        ) {
            e.preventDefault()

            const targetId = e.target.getAttribute('href').substring(1)
            const targetElement = document.getElementById(targetId)

            if (targetElement) {
                const gap = 40
                const elementPosition =
                    targetElement.getBoundingClientRect().top + window.scrollY
                const offsetPosition =
                    elementPosition - getHeaderHeight() - gap

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                })
            }
        }
    }

    document.body.addEventListener('click', handleAnchorClick)
})
