function setCookie(name, value, days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + date.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

function getCookie(name) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length)
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length)
        }
    }

    return null
}

function checkCookie(el, btn) {
    const cookieConsent = getCookie('cookieConsent')
    if (!cookieConsent && el && btn) {
        el.style.display = 'block'

        btn.addEventListener('click', () => {
            setCookie('cookieConsent', 'accepted', 365)
            el.style.display = 'none'
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const banner = document.querySelector('#cookie-banner')
    const bannerBtn = banner.querySelector('button')

    checkCookie(banner, bannerBtn)
})

