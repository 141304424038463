import './../../scss/blocks/_main_form.scss'
import MicroModal from 'micromodal'

let captchaNum1, captchaNum2, encodedNum1, encodedNum2
// eslint-disable-next-line no-undef
const FORM_URL = data.ajax_url

function generateCaptcha(form) {
    const captchaInput = form.querySelector('input[name="check"]')
    const captchaLabel = form.querySelector('.main-form__field .field__label[for="check"]')
    if (!captchaLabel || !captchaInput) {
        return
    }

    captchaNum1 = Math.floor(Math.random() * 10) + 1
    captchaNum2 = Math.floor(Math.random() * 10) + 1
    encodedNum1 = btoa(captchaNum1.toString())
    encodedNum2 = btoa(captchaNum2.toString())

    captchaLabel.innerHTML = `Антиспам: ${captchaNum1} + ${captchaNum2} = ?`

    let hiddenNum1 = form.querySelector('input[name="encoded_num1"]')
    let hiddenNum2 = form.querySelector('input[name="encoded_num2"]')

    if (!hiddenNum1) {
        hiddenNum1 = document.createElement('input')
        hiddenNum1.type = 'hidden'
        hiddenNum1.name = 'encoded_num1'
        form.appendChild(hiddenNum1)
    }

    if (!hiddenNum2) {
        hiddenNum2 = document.createElement('input')
        hiddenNum2.type = 'hidden'
        hiddenNum2.name = 'encoded_num2'
        form.appendChild(hiddenNum2)
    }

    hiddenNum1.value = encodedNum1
    hiddenNum2.value = encodedNum2
}

function handleForm(formType) {
    const form = document.querySelector(`[data-feedback-form="${formType}"]`)
    if (!form) {
        return
    }

    let isDirty = false
    let captchaUpdated = false
    const submitButton = form.querySelector('.main-form__btn')
    const fields = form.querySelectorAll('.main-form__field')

    generateCaptcha(form)

    fields.forEach(field => {
        const input = field.querySelector('.field__input')
        input.addEventListener('blur', () => {
            if (isDirty) {
                validateField(field)
            }
        })
        input.addEventListener('input', () => {
            if (isDirty) {
                validateField(field)
            }
        })
    })

    form.addEventListener('submit', async (event) => {
        event.preventDefault()

        isDirty = true

        if (!validateForm(fields)) {
            if (!captchaUpdated) {
                captchaUpdated = true
            }

            return
        }

        setLoadingState(true, submitButton)
    
        try {
            const formData = new FormData(form)
            const response = await fetch(FORM_URL, {
                method: 'POST',
                body: formData,
            })
    
            if (!response.ok) {
                throw new Error('Ошибка отправки формы.')
            }
    
            const result = await response.json()
    
            if (result.success) {
                MicroModal.show(`${formType}Success`)
                form.reset()
                generateCaptcha(form)
            } else {
                throw new Error(result.message || 'Ошибка обработки данных.')
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        } finally {
            setLoadingState(false, submitButton)
        }
    })
}

function validateForm(fields) {
    return [...fields].reduce((isValid, field) => {
        return Boolean(validateField(field) && isValid)
    }, true)
}

function validateField(field) {
    let isValid = true

    const input = field.querySelector('.field__input')
    const value = input.value.trim()
    const name = input.name

    if (name === 'check' && parseInt(value, 10) !== captchaNum1 + captchaNum2) {
        isValid = false
        showError(field, 'Неверный ответ на антиспам')
    } else if (name === 'name' && value.length < 2) {
        isValid = false
        showError(field, 'Имя должно содержать минимум 2 символа')
    } else if (name === 'company' && value.length < 2) {
        isValid = false
        showError(field, 'Название компании должно содержать минимум 2 символа')
    } else if (name === 'phone' && !/^\+?\d{10,15}$/.test(value)) {
        isValid = false
        showError(field, 'Введите корректный номер телефона, например +77777777777 (11 цифр)')
    } else if (name === 'email' && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        isValid = false
        showError(field, 'Введите корректный адрес электронной почты')
    } else {
        clearError(field)
    }

    return isValid
}

function showError(field, message) {
    field.classList.add('field--error')
    let errorElement = field.querySelector('.field__error')
    if (!errorElement) {
        errorElement = document.createElement('div')
        errorElement.className = 'field__error'
        field.appendChild(errorElement)
    }
    errorElement.textContent = message
}

function clearError(field) {
    field.classList.remove('field--error')
    const errorElement = field.querySelector('.field__error')
    if (errorElement) {
        errorElement.textContent = ''
    }
}

function setLoadingState(isLoading, submitButton) {
    if (isLoading) {
        submitButton.classList.add('button--loading')
    } else {
        submitButton.classList.remove('button--loading')
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('[data-feedback-form]')
    forms.forEach(form => {
        const type = form.dataset.feedbackForm
        handleForm(type)
    })
})
