document.addEventListener('DOMContentLoaded', function() {
    const menuItems = document.querySelectorAll('.menu-item-has-children')

    menuItems.forEach(function(menuItem) {
        menuItem.addEventListener('mouseenter', function() {
            const subMenu = menuItem.querySelector('.sub-menu')
            if (subMenu) {
                subMenu.classList.add('open')
            }
        })

        menuItem.addEventListener('mouseleave', function() {
            const subMenu = menuItem.querySelector('.sub-menu')
            if (subMenu) {
                subMenu.classList.remove('open')
            }
        })
    })
})
