document.addEventListener('DOMContentLoaded', function() {
    const accordions = document.querySelectorAll('[data-item="vacancy-accordion"]')

    accordions.forEach((accordion) => {
        const header = accordion.querySelector('.vacancy-accordion__header')
        const content = accordion.querySelector('.vacancy-accordion__content')
        const btn = accordion.querySelector('[data-item="vacancy-accordion-control"]')

        const contentClass = 'vacancy-accordion__content--opened'
        const headerClass = 'vacancy-accordion__header--opened'
        const btnClass = 'vacancy-accordion__btn--active'

        header.addEventListener('click', function() {
            header.classList.toggle(headerClass)
            content.classList.toggle(contentClass)
            btn.classList.toggle(btnClass)
        })
    })
})
